import React from 'react'
import Layout from '../components/Layout'

const NotFoundPage = () => (
  <Layout>
    <section id="404" className="section section--gradient">
      <div className="container">
        <div className="box">
          <div className="section">
            <div className="content">
              <h1
                className="title has-text-weight-bold has-text-black"
                style={{ marginRight: "auto" }}
              >
                Erreur 404
              </h1>
            </div>
            <div>
              <p>La page demandée n'as pas été trouvée.</p>
            </div>
           
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage
